<template>
    <div class="comments">
        <van-card
            :price="data.price"
            :thumb="data.thumb_pic"
            :title="data.title"
            :desc="data.desc"
            :num="data.num"
        >
        </van-card>

        <div class="edit-comments">
            <div class="title">评价内容</div>
            <van-field
                v-model="content"
                autosize
                rows="6"
                type="textarea"
                maxlength="144"
                placeholder="最大可输入144个字"
                show-word-limit
            />
        </div>

         <van-button :disabled="disabled" @click="submit" round color="linear-gradient(to right, #ff6034, #ee0a24)">
            提交评价
        </van-button>
    </div>
</template>

<script>
export default {
    name: "Comments",
	created() {
	    let data = sessionStorage.getItem("commentsData");
        if (data) {
            this.data = JSON.parse(data);
        } else {
            this.$toast({
                message: "请先选择商品",
                overlay: true,
                forbidClick: true,
                onClose: () => {
                    this.$router.replace("/my?active=2");
                }
            })
        }
	},
    data() {
        return {
            data: {},
            content: "",
            disabled: false
        }
    },
    methods: {
        async submit() {
            if (!this.content) {
                this.$toast("请填写评价");
                return;
            }
            this.disabled = true;
            const res = await axios.post("/add_comment", {
                order_sn: this.data.sn,
                comment: this.content
            })
            if (res.code == 1) {
                this.$toast({
                    message: "评价成功",
                    onClose: () => {
                        this.$router.replace("/my?active=2");
                    }
                })             
            } else {
                this.disabled = false;
                this.$toast(res.message)
            }
            console.log(res);
        }
    },

}
</script>

<style lang="less" scoped>
.comments {
    background: #FAFAFA;
    min-height: 100vh;
    padding: 12px 15px;
    box-sizing: border-box;
}
.van-card {
    background: #fff;
    border-radius: 5px;
    padding: 10px 14px;
    .van-card__thumb {
        width: 113px;
        height: 115px;
    }
    .van-card__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .van-card__title {
        color: #000;
        font-size: 14px;
        display: block;
        white-space: nowrap;
        -webkit-line-clamp: 1;
    }
    .van-card__price {
        color: #EC2627;
        font-weight: bold;
        font-size: 10px;
    }
    .van-card__price-currency {
        font-size: 9px;
    }
    .van-card__price-integer {
        font-size: 17px;
    }
    /deep/ .van-image__img {
        box-shadow: 0px 2px 7px 2px rgba(246, 246, 246, 0.73);
    }
    .van-card__desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: none;
        white-space: normal;
    }
}
.edit-comments {
    padding: 15px 15px 20px;
    margin: 10px 0 70px;
    background: #fff;
    .title {
        color: #666;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 9px;
    }
    .van-field {
        border-radius: 5px;
        background: #FAFAFA;
        padding: 10px;
        font-size: 12px;
        line-height: 1.5;
    }
    /deep/ .van-field__control {
        color: #232323;
    }
    /deep/ .van-field__word-limit {
        text-align: left;
        color: #CECECE;
    }
    /deep/ textarea::placeholder {
        color: #999;
    }
}
.van-button {
    width: 100%;
    height: 40px;
}
</style>